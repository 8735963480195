<template>
  <div class="menu">
    <template v-for="item in items" :key="item">
      <router-link :to="item.to" :class="{ item: true, 'item--active': isCurrent(item) }" @click="closeSidebar">
        <span class="item__icon">
          <span class="item__icon-inner">
            <Icon :name="item.icon" />
          </span>
        </span>
        <span class="item__text">
          {{ item.text }}
        </span>
      </router-link>

      <div class="submenu" v-if="isCurrent(item) && item.submenu">
        <template v-for="subitem in item.submenu" :key="subitem">
          <router-link :to="subitem.to" :class="{ subitem: true }" @click="closeSidebar">
            <span class="subitem__text">
              {{ subitem.text }}
            </span>
          </router-link>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import { mainMenuItems } from "@/services/mainMenu";
import Icon from "@/components/deprecated/icons/Icon.vue";

export default {
  data() {
    return {
      items: mainMenuItems.get(),
    };
  },
  methods: {
    isCurrent(item) {
      const itemName = item.to.name;
      const module = itemName.split("-")[0];
      const currentModule = this.$route.name.split("-")[0];
      return module == currentModule;
    },
    closeSidebar() {
      window.eventBus.emit(events.layout.closeSidebar);
    },
  },
  components: {
    Icon,
  },
};
</script>

<style lang="scss" scoped>
.menu {
  --item-height: 50px;

  @include for-size(md) {
    --item-height: 40px;
  }

  @include for-size(sm) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 0 var(--side-padding);
    width: 100%;
  }
}
.item {
  display: flex;
  align-items: center;
  height: var(--item-height);
  margin: 0 calc(var(--sidebar-padding) * -1);
  padding: 0 var(--sidebar-padding);
  position: relative;

  &__icon {
    width: var(--menu-width);
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__icon-inner {
    aspect-ratio: 1/1;
    width: 23px;
    color: #fff;
    svg {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }
  &__text {
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    white-space: nowrap;
  }
  &:hover {
    text-decoration: none;
    .item__icon-inner {
      color: var(--primary-color);
    }
    .item__text {
      color: var(--primary-color);
    }
  }
  &:focus {
    .item__text {
      color: #fff;
    }
  }
  &--active {
    .item__icon-inner {
      color: var(--primary-color);
    }
    .item__text {
      color: var(--primary-color);
    }
  }

  @include for-size(md) {
    &:before {
      height: 15px;
    }
    &--active {
      &:before {
        transform: scaleY(1.3) translate(-60%, 50%) rotate(45deg);
      }
    }
  }
}
.subitem {
  position: relative;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  line-height: 1.2;
  margin-bottom: 20px;
  &:before {
    content: "•";
    width: var(--menu-width);
    display: block;
    flex-shrink: 0;
    text-align: center;
  }
  &:hover {
    text-decoration: none;
    color: var(--primary-color);
  }
}
</style>
