import { AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { NoUserException, requireUser } from "@/services/user";
import { axios } from "./axiosFactory";

export const TokenHeader = "AuthorizationToken";

export const LocaleQueryParamName = "webPrefixCode"; // Remove "export" when old api client will be removed
export const LocaleQueryParamValue = "cz"; // Hardcoded - no translations in admin yet
export const CurrencyIdQueryParamName = "primaryCurrencyId"; // Remove "export" when old api client will be removed
export const CurrencyIdQueryParamValue = 1; // Hardcoded - in admin display everything in CZK = 1

export enum TokenAdd {
  Never,
  IfAvailable,
  Always,
}

export const API = {
  async get<ResponseDataType>(url: string, params: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest<ResponseDataType>("GET", url, params, {}, TokenAdd.Always);
  },

  async getPublic<ResponseDataType>(url: string, params: object = {}, addAuthToken: TokenAdd = TokenAdd.IfAvailable): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("GET", url, params, {}, addAuthToken);
  },

  async post<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("POST", url, params, data, TokenAdd.Always);
  },

  async postPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken: TokenAdd = TokenAdd.IfAvailable): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("POST", url, params, data, addAuthToken);
  },

  async put<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PUT", url, params, data, TokenAdd.Always);
  },

  async putPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken: TokenAdd = TokenAdd.IfAvailable): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PUT", url, params, data, addAuthToken);
  },

  async delete<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("DELETE", url, params, data, TokenAdd.Always);
  },

  async deletePublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken: TokenAdd = TokenAdd.IfAvailable): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("DELETE", url, params, data, addAuthToken);
  },

  async patch<ResponseDataType>(url: string, params: object = {}, data: object = {}): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PATCH", url, params, data, TokenAdd.Always);
  },

  async patchPublic<ResponseDataType>(url: string, params: object = {}, data: object = {}, addAuthToken: TokenAdd = TokenAdd.IfAvailable): Promise<AxiosResponse<ResponseDataType>> {
    return makeRequest("PATCH", url, params, data, addAuthToken);
  },
};

async function makeRequest<ResponseDataType>(method: Method, url: string, params: object = {}, data: object = {}, addAuthToken: TokenAdd = TokenAdd.Always): Promise<AxiosResponse<ResponseDataType>> {
  const config = <AxiosRequestConfig>{
    url,
    method,
    params: {
      ...params,
      [LocaleQueryParamName]: LocaleQueryParamValue,
      [CurrencyIdQueryParamName]: CurrencyIdQueryParamValue,
    },
    data,
  };

  if (addAuthToken === TokenAdd.Always) {
    addToken(config, requireUser().getToken());
  } else if (addAuthToken === TokenAdd.IfAvailable) {
    try {
      const user = requireUser();
      const token = user.getToken();
      addToken(config, token);
    } catch (e: unknown) {
      if (e instanceof NoUserException) {
        // It's ok - no user available - do not add token.
      }
    }
  }

  return axios.request<ResponseDataType>(config);
}

function addToken(config: AxiosRequestConfig, token: string): void {
  if (typeof config.headers === "undefined") {
    config.headers = {};
  }

  config.headers[TokenHeader] = token;
}
