const AccountsList = () => import("../view/AccountsList.vue");
const AccountLayout = () => import("../component/AccountLayout.vue");
const AccountMainInfo = () => import("../view/AccountMainInfo.vue");
const AccountRights = () => import("../view/AccountRights.vue");
const AccountLogins = () => import("../view/AccountLogins.vue");

const BASE_NAME = "accounts-";
const BASE_URL = "/uzivatele/";

export default [
  {
    name: BASE_NAME + "list",
    path: BASE_URL,
    components: { mainContent: AccountsList },
    meta: {
      title: "Správa uživatelů",
      layout: "admin",
      right: "accounts",
    },
  },
  {
    name: BASE_NAME + "detail",
    path: BASE_URL + "detail/:id",
    components: { mainContent: AccountLayout },
    meta: {
      layout: "admin",
      right: "accounts",
    },
    children: [
      {
        name: BASE_NAME + "detail-mainInfo",
        path: "zakladni-info",
        components: {
          tab: AccountMainInfo,
        },
      },
      {
        name: BASE_NAME + "detail-rights",
        path: "opravneni",
        components: {
          tab: AccountRights,
        },
      },
      {
        name: BASE_NAME + "detail-logins",
        path: "prihlaseni",
        components: {
          tab: AccountLogins,
        },
      },
    ],
  },
];
