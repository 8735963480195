import { createNotificationsListLink } from "@/router";
import { store } from "@/store/store";

const items = [
  {
    text: "Nástěnka",
    to: { name: "dashboard" },
    icon: "chart-line-up",
  },
  {
    text: "Články",
    to: { name: "articles-list" },
    icon: "book",
    right: "articles",
    submenu: [
      {
        text: "Autoři",
        to: { name: "articles-authors-list" },
      },
      {
        text: "Ankety",
        to: { name: "articles-surveys-list" },
      },
    ],
  },
  {
    text: "Notifikace",
    to: createNotificationsListLink(),
    icon: "messages",
    right: "notifications",
  },
  {
    text: "Uživatelé",
    to: { name: "accounts-list" },
    icon: "users",
    right: "accounts",
  },
];

const mainMenuItems = {
  get: function () {
    const user = store.getters.getUser;

    return items.filter((item) => {
      if (item.right) {
        return user.hasRight(item.right);
      } else {
        return true;
      }
    });
  },
};

export { mainMenuItems };
